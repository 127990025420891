<template>
  <div class="article-card" @click="onViewEvent(data?.EventCode)">
    <div v-if="data?.EventCode" class="content">
      <div class="image">
        <img v-if="data?.Image" loading="lazy" :src="$h.getImage(data?.Image, 'EVENT')" :alt="$t.getTranslation('LBL_EVENT_IMAGE')" />
      </div>

      <div v-if="data?.Title" class="content">
        <h1>{{ $h.formatTrimString(data?.Title, 80) }}</h1>
      </div>

      <div v-if="data?.Description" class="content">
        <p>{{ $h.formatTrimString(data?.Description, 80) }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, inject } from "vue";
import { useStore } from "@/store";

import { f7 } from "framework7-vue";

export default defineComponent({
  name: "EventCardComponent",
  components: {},
  props: {
    data: Object,
  },
  setup(props) {
    const store = useStore();

    const $f7router = f7.views.get("#main-view").router;

    const onViewEvent = (code) => {
      $f7router.navigate({ name: "eventsViewPage", params: { code } });
    };

    return { onViewEvent, store };
  },
});
</script>
